<template>
  <el-container>
    <el-main>
      <div class="login">
        <el-card class="login-box-card">
          <div slot="header" class="clearfix">
            <div class="login-center">
              <h1>工业气体管理平台</h1>
            </div>
          </div>
          <el-form ref="form" :model="group" label-width="80px">
            <el-input v-model="group.name" placeholder="请输入公司（集团）名称"  prefix-icon="el-icon-s-home"></el-input>
          </el-form>
          <div class="login-left">
            <el-button type="primary" @click="loginFn" style="width:100%;margin-bottom:15px;">确认</el-button>
          </div>
        </el-card>
      </div>
    </el-main>
    <el-footer>
      <div class="login-log">
        <a href="http://beian.miit.gov.cn/"><span style="color: white; ;font-size:12px;" >京ICP备12025852号-2</span></a>
        &nbsp;&nbsp;&nbsp;
        <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502046548" ><span style="color:white;font-size:12px" >京公网安备11010502046548号</span></a>
      </div>
    </el-footer>

    <!--  底部  -->

  </el-container>
</template>

<script>
// 导入 api 获得ajax操作
// import {login} from "../api";
export default {

  data() {
    return {
      group: {
        name:'',
        groupUrl:''
      },
      group_url: [
          {name: "湖北和远气体股份有限公司", url: "http://site.hbigs.com/"},
          {name: "体验", url: "http://preview.hbigs.com/"},
          {name: "测试", url: "http://test.hbigs.com/"},
      ]
    }
  },
  methods: {
    // async
    loginFn() {
      // 取值
      // this.group

      this.group_url.forEach((item)=>{
        if(item.name===this.group.name){
          window.location= item.url
          return;
        }
      });

      /*//发送ajax
      let {data: baseResult} = await login(this.group)
      //处理
      if (baseResult.code == 1) {
        //成功提示
        this.$message.success(baseResult.message)
        //跳转到首页
        // this.$router.push('/home')
      } else {
        this.$message.error(baseResult.message)
      }*/
    }
  },
}
</script>

<style>
.login {
  height: 100%;
  display: flex; /* css3 新取值，允许居中 */
  align-items: center; /* 上下居中*/
  justify-content: center; /* 左右居中 */
}
.login-log{
  height: 100%;
  display: flex; /* css3 新取值，允许居中 */
  align-items: center; /* 上下居中*/
  justify-content: center; /* 左右居中 */
}
.login-left{
  height: 100%;
  display: flex; /* css3 新取值，允许居中 */
  justify-content: center;
}
.login-box-card {
  width: 400px;
  height: 320PX;
  opacity: 0.97;
}
.login-center{
  height: 30px;
  display: flex; /* css3 新取值，允许居中 */
  align-items: center; /* 上下居中*/
  justify-content: center; /* 左右居中 */
}
body{
  background-image: url('../assets/image/login-background.jpg');
  background-size: cover;
  background-position: center;
  font: 14px/1.5 tahoma,arial,'Hiragino Sans GB','\5b8b\4f53',sans-serif;
  color: #4d4d4d;
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
}

.el-footer {
  text-align: center;
}


.el-main {
  height:93vh;
  text-align: center;
  line-height: 160px;
}
</style>
